import { useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import moment from "moment";
import { FilterCriteria } from "../../../contracts/models/Swings/FilterCriteria";
import { SwingsQuery } from "../../../contracts/models/Queries/SwingsQuery";
import { useSwings } from "../../../hooks/useSwings";
import { buildQueryParams } from "../../../hooks/mantine/mantineTableHooks";

export function useGetSwings(query: SwingsQuery) {
  const location = useLocation();
  const queryString = useMemo(() => buildQueryParams(query), [query]);
  useMemo(() => {
    if (queryString) {
      window.history.replaceState(
        {},
        "",
        `${location.pathname}?${queryString}`
      );
    }
  }, [queryString, location.pathname]);
  return useSwings(queryString);
}

export function useSwingMantineTableState(): {
  customFilters: FilterCriteria;
  setCustomFilters: (value: FilterCriteria) => void;
} {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const getFilterValue = (key: string, defaultValue: any) => {
    const value = queryParams.get(key);
    try {
      return value !== null ? JSON.parse(value) : defaultValue;
    } catch (e) {
      console.error(`Error parsing query param ${key}:`, e);
      return defaultValue;
    }
  };

  const parseDate = (dateStr: moment.MomentInput, defaultValue: Date) => {
    const date = moment(dateStr);
    return date.isValid() ? date.toDate() : defaultValue;
  };

  const initialFilters = useMemo(() => {
    const filters = getFilterValue("filters", []);

    return {
      fromDate: parseDate(
        filters.find((filter: any) => filter.id === "fromDate")?.value,
        moment().subtract(1, "days").startOf("day").toDate()
      ),
      toDate: parseDate(
        filters.find((filter: any) => filter.id === "toDate")?.value,
        moment().endOf("day").toDate()
      ),
      pending:
        filters.find((filter: any) => filter.id === "pending")?.value ?? true,
      available:
        filters.find((filter: any) => filter.id === "available")?.value ?? true,
      accepted:
        filters.find((filter: any) => filter.id === "accepted")?.value ?? true,
      current:
        filters.find((filter: any) => filter.id === "current")?.value ?? true,
      progress:
        filters.find((filter: any) => filter.id === "progress")?.value ?? true,
      completed:
        filters.find((filter: any) => filter.id === "completed")?.value ?? true,
      closed:
        filters.find((filter: any) => filter.id === "closed")?.value ?? true,
      failed:
        filters.find((filter: any) => filter.id === "failed")?.value ?? true,
      allocated:
        filters.find((filter: any) => filter.id === "allocated")?.value ?? true,
      cancelled:
        filters.find((filter: any) => filter.id === "cancelled")?.value ?? true,
      searchTerm:
        filters.find((filter: any) => filter.id === "searchTerm")?.value ?? "",
    };
  }, [queryParams]);

  const [customFilters, setCustomFilters] =
    useState<FilterCriteria>(initialFilters);

  return {
    customFilters,
    setCustomFilters,
  };
}
