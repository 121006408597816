// src/components/jobs/job-list.component.tsx
import React, { useEffect, useState } from "react";
import { MantineReactTable, MRT_ColumnFilterFnsState, MRT_ColumnFiltersState, MRT_RowSelectionState, useMantineReactTable } from "mantine-react-table";
import { updateQueryParams, useColumnVisibility, useMantineTableState } from "../../../hooks/mantine/mantineTableHooks";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { DriverJobDto } from "../../../contracts/models/DriverJobDto";
import { ActionIcon, Box } from "@mantine/core";
import { Link } from "react-router-dom";
import { IconNavigation } from "@tabler/icons-react";
import { columns, defaultColumnVisibility } from "./driver-jobs-list-columns";
import { useGetDriverJobs } from "./driver-jobs-list-hooks";

export default function DriverJobsList() {
    const queryClient = new QueryClient();
    const { pagination, setPagination, sorting, setSorting, columnFilters, setColumnFilters } = useMantineTableState();
    const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
    const { columnVisibility, setColumnVisibility } = useColumnVisibility(defaultColumnVisibility, "driverJobs:columnVisibility");
    const [columnFilterFns, setColumnFilterFns] = useState<MRT_ColumnFilterFnsState>(
        Object.fromEntries(
            columns.map(({ accessorKey }) => [accessorKey, "contains"])
        )
    );
    
    useEffect(() => {
        updateQueryParams({
          columnFilters,
          sorting,
          pagination,
        });
      }, [pagination.pageIndex, pagination.pageSize]);
    
      useEffect(() => {
        localStorage.setItem(
          "driverJobs:columnVisibility",
          JSON.stringify(columnVisibility)
        );
      }, [columnVisibility]);

    const { data, isError, isFetching, isLoading } = useGetDriverJobs({
        columnFilterFns,
        columnFilters,
        pagination,
        sorting,
    });

    const table = useMantineReactTable({
        columns,
        data: data?.data ?? [],
        enableColumnFilterModes: true,
        enableGlobalFilter: false,
        enableRowSelection: false,
        columnFilterModeOptions: ["contains", "startsWith", "endsWith"],
        initialState: {
            showColumnFilters: true,
            columnVisibility,
        },
        onColumnVisibilityChange: setColumnVisibility,
        manualFiltering: true,
        manualPagination: true,
        manualSorting: true,
        mantineToolbarAlertBannerProps: isError
            ? {
                color: "red",
                children: "Error loading data",
            }
            : undefined,
        onColumnFilterFnsChange: setColumnFilterFns,
        onColumnFiltersChange: setColumnFilters,
        onPaginationChange: setPagination,
        onSortingChange: setSorting,
        getRowId: (row: DriverJobDto) => row.id?.toString(),
        mantineTableBodyRowProps: ({ row }) => ({
            onClick: () => {
                setRowSelection((prev) => ({
                    ...prev,
                    [row.id]: !prev[row.id],
                }));
            },
            selected: rowSelection[row.id],
            sx: {
                cursor: "pointer",
            },
        }),
        rowCount: data?.meta?.totalRowCount ?? 0,
        state: {
            columnFilterFns,
            columnFilters,
            columnVisibility,
            rowSelection,
            isLoading,
            pagination,
            showAlertBanner: isError,
            showProgressBars: isFetching,
            sorting,
        },
    });

    if (isError) {
        return <div className="alert alert-danger">Failed to load jobs</div>;
    }

    return (
        <QueryClientProvider client={queryClient}>
            <MantineReactTable table={table} />
        </QueryClientProvider>
    );
}