import { SwingApiResponse } from "../contracts/models/Swings/SwingApiResponse";
import SwingService from "../services/swing.service";
import { useQuery } from "@tanstack/react-query";

export function useSwings(searchParams: string) {
  return useQuery<SwingApiResponse>({
    queryKey: ["swings", searchParams],
    queryFn: async () => (await SwingService.getSwings(searchParams)).data,
    staleTime: 30_000, //don't refetch previously viewed pages until cache is more than 30 seconds old
  });
}


