import { MRT_ColumnDef } from "mantine-react-table";
import { DriverJobDto } from "../../../contracts/models/DriverJobDto";
import moment from "moment";
import { DatePicker } from "@mantine/dates";
import React from "react";
import Status from "../../jobs/status.component";
import { getStatusString } from "../../dashboard/swing-list/swing-list-utils";
import ViewJob from "../../jobs/view-job.component";

const columns: MRT_ColumnDef<DriverJobDto>[] = [
  {
    accessorKey: "jobDescription",
    header: "Job Description",
    enableColumnFilter: false,
    enableSorting: true,
    id: "jobDescription",
  },
  {
    accessorFn: (job: DriverJobDto) => getStatusString(job.swing?.jobs || []),
    Cell: ({ cell }) => <Status status={cell.getValue() as string} />,
    header: "Status*",
    enableColumnFilter: false,
    enableSorting: false,
    id: "status",
  },
  {
    accessorKey: "isTeamLeader",
    header: "Is Team Leader",
    enableColumnFilter: false,
    enableSorting: true,
    id: "isTeamLeader",
  },
  {
    accessorFn: (job: DriverJobDto) => job.vehicles?.map(vehicle => vehicle.vehicle?.registration).join(", "),
    header: "Vehicles",
    enableColumnFilter: false,
    enableSorting: true,
    id: "vehicles",
  },
  {
    accessorFn: (job: DriverJobDto) => job.swing?.jobReference ?? "",
    header: "Job Reference",
    enableColumnFilter: true,
    enableSorting: true,
    id: "jobReference",
  },
  {
    accessorFn: (job: DriverJobDto) => job.swing?.collectionDate ? moment(job.swing.collectionDate).format("DD/MM/YYYY") : "",
    header: "Collection Date",
    enableColumnFilter: true,
    enableSorting: true,
    id: "collectionDate",
    filterVariant: 'date',
  },
  {
    accessorFn: (job: DriverJobDto) => job.jobClassRequirement?.name ?? "",
    header: "Job Class Requirement",
    enableColumnFilter: false,
    enableSorting: true,
    id: "jobClassRequirement",
  },
  {
    accessorFn: (job: DriverJobDto) => job.driver?.name ?? "",
    header: "Driver",
    enableColumnFilter: true,
    enableSorting: true,
    id: "driverName",
  },
  {
    accessorFn: (job: DriverJobDto) => job,
    Cell: ({ cell }) => {
      return cell.getValue<DriverJobDto>().id ? <ViewJob
        vehicles={cell.getValue<DriverJobDto>().vehicles}
        swing={cell.getValue<DriverJobDto>().swing}
        job={cell.getValue<DriverJobDto>()}></ViewJob>
        : null
    },
    header: "Action",
    enableColumnFilter: false,
    enableSorting: true,
    id: "action",
  },
];

export const defaultColumnVisibility = {
  action: true,
  jobDescription: true,
  status: true,
  isTeamLeader: true,
  vehicles: true,
  jobReference: true,
  jobClassRequirement: true,
  collectionDate: true,
  driverName: true,
};

export { columns };