import { AxiosResponse } from "axios";
import api from "./network";
import { DriverJobsApiResponse } from "../contracts/models/DriverJobs/DriverJobsApiResponse";

const API_URL = "DriverJob/";

class DriverJobsService {
 
    getDriverJobs(searchParams: string): Promise<AxiosResponse<DriverJobsApiResponse>> {
        return api.get(`${API_URL}driver-jobs?${searchParams}`);
      }

}
export default new DriverJobsService();
