import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { buildQueryParams } from "../../../hooks/mantine/mantineTableHooks";
import { DriverJobsQuery } from "../../../contracts/models/Queries/DriverJobsQuery";
import { useDriverJobsNew } from "../../../hooks/useDriverJobs";

export function useGetDriverJobs(query: DriverJobsQuery) {
  const location = useLocation();
  const queryString = useMemo(() => buildQueryParams(query), [query]);
  useMemo(() => {
    if (queryString) {
      window.history.replaceState(
        {},
        "",
        `${location.pathname}?${queryString}`
      );
    }
  }, [queryString, location.pathname]);
  return useDriverJobsNew(queryString);
}

