import { DriverJobsApiResponse } from "../contracts/models/DriverJobs/DriverJobsApiResponse";
import { useQuery } from "@tanstack/react-query";
import driverService from "../services/driver.service";
import driverJobsService from "../services/driver-jobs.service";

export function useDriverJobs(driverId: any) {
  return useQuery({
    queryKey: ["driverJobs", driverId],
    queryFn: async () => {
      const response = await driverService.getDriverJobs(driverId);
      return response.data;
    }
  });
}

export function useDriverJobsNew(searchParams: string) {
  return useQuery<DriverJobsApiResponse>({
    queryKey: ["swings", searchParams],
    queryFn: async () => (await driverJobsService.getDriverJobs(searchParams)).data,
    staleTime: 30_000, //don't refetch previously viewed pages until cache is more than 30 seconds old
  });
}
