import { useEffect, useState } from "react";
import {
  MantineReactTable,
  useMantineReactTable,
  MRT_ColumnFiltersState,
  MRT_ColumnFilterFnsState,
  MRT_RowSelectionState,
} from "mantine-react-table";
import { ActionIcon, Box, Button, Tooltip } from "@mantine/core";
import { IconDownload, IconNavigation, IconRefresh } from "@tabler/icons-react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import React from "react";
import { Link } from "react-router-dom";
import { FilterCriteria } from "../../../contracts/models/Swings/FilterCriteria";
import { SwingDto } from "../../../contracts/models/SwingDto";
import Filter from "../../jobs/filter.component";
import { useGetSwings, useSwingMantineTableState } from "./swing-list-hooks";
import { columns, defaultColumnVisibility } from "./swing-list-columns";
import "../swing-list/swing-list.css";
import { handleExportData, handleExportRows, updateQueryParams, useColumnVisibility, useMantineTableState } from "../../../hooks/mantine/mantineTableHooks";

export default function SwingsList() {
  const { customFilters, setCustomFilters} = useSwingMantineTableState();
  const { pagination, setPagination, sorting, setSorting } = useMantineTableState();
  const queryClient = new QueryClient();

  const handleCustomFilterChange = (
    filterName: keyof FilterCriteria,
    value: any
  ) => {
    const newFilters = { ...customFilters, [filterName]: value };
    updateQueryParams({
      columnFilters,
      sorting,
      pagination,
      customFilters: newFilters,
    });
    setCustomFilters(newFilters);
  };

  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>([]);
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const { columnVisibility, setColumnVisibility } = useColumnVisibility(defaultColumnVisibility, "dashboard:columnVisibility");
  const [columnFilterFns, setColumnFilterFns] = useState<MRT_ColumnFilterFnsState>(
    Object.fromEntries(
      columns.map(({ accessorKey }) => [accessorKey, "contains"])
    )
  );

  const transformRowData = (item: SwingDto) => {
    const { customer, meetLocation, ...rest } = item;
    return {
      ...rest,
      customer: customer?.name || "",
      meetLocation: [
        meetLocation?.line1,
        meetLocation?.line2,
        meetLocation?.postcode,
      ]
        .filter(Boolean)
        .join(", "),
    };
  };

  useEffect(() => {
    updateQueryParams({
      columnFilters,
      sorting,
      pagination,
      customFilters,
    });
  }, [pagination.pageIndex, pagination.pageSize]);

  useEffect(() => {
    localStorage.setItem(
      "dashboard:columnVisibility",
      JSON.stringify(columnVisibility)
    );
  }, [columnVisibility]);

  const { data, isError, isFetching, isLoading, refetch } = useGetSwings({
    columnFilterFns,
    columnFilters,
    pagination,
    sorting,
    customFilters,
  });

  const table = useMantineReactTable({
    columns,
    data: data?.data ?? [],
    enableColumnFilterModes: true,
    enableGlobalFilter: false,
    enableRowSelection: false,
    columnFilterModeOptions: ["contains", "startsWith", "endsWith"],
    initialState: {
      showColumnFilters: true,
      columnVisibility,
    },
    onColumnVisibilityChange: setColumnVisibility,
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    mantineToolbarAlertBannerProps: isError
      ? {
          color: "red",
          children: "Error loading data",
        }
      : undefined,
    enableRowActions: true,
    renderRowActions: ({ row }) => (
      <Box sx={{ display: "flex", flexWrap: "nowrap", gap: "8px" }}>
        <Link to={`/swing/view/${row.id}`}>
          <ActionIcon color="blue">
            <IconNavigation />
          </ActionIcon>
        </Link>
      </Box>
    ),
    onColumnFilterFnsChange: setColumnFilterFns,
    onColumnFiltersChange: setColumnFilters,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    getRowId: (row: SwingDto) => row.id?.toString(),
    mantineTableBodyRowProps: ({ row }) => ({
      onClick: () => {
        setRowSelection((prev) => ({
          ...prev,
          [row.id]: !prev[row.id],
        }));
      },
      selected: rowSelection[row.id],
      sx: {
        cursor: "pointer",
      },
    }),
    renderTopToolbarCustomActions: () => (
      <>
        <div className="w-100">
          <Filter
            onChange={handleCustomFilterChange}
            filters={customFilters}
            jobs={data}
          />
          <div className="d-flex justify-content-center justify-content-md-start flex-wrap my-3">
            <Button
              className="btn-primary"
              onClick={() => handleExportData({data: data?.data ?? []} , transformRowData)}
              leftIcon={<IconDownload />}
              variant="filled"
            >
              Export Page
            </Button>
            <Button
              className="btn-primary"
              disabled={
                rowSelection && Object.values(rowSelection).every((v) => !v)
              }
              onClick={() => handleExportRows(table.getSelectedRowModel().rows, transformRowData)}
              leftIcon={<IconDownload />}
              variant="filled"
            >
              Export Selected
            </Button>
          </div>
        </div>
        <Tooltip label="Refresh Data">
          <ActionIcon onClick={() => refetch()}>
            <IconRefresh />
          </ActionIcon>
        </Tooltip>
      </>
    ),
    rowCount: data?.meta?.totalRowCount ?? 0,
    state: {
      columnFilterFns,
      columnFilters,
      columnVisibility,
      rowSelection,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isFetching,
      sorting,
    },
  });

  return (
    <QueryClientProvider client={queryClient}>
      <MantineReactTable table={table} />
    </QueryClientProvider>
  );
}
